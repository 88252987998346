<template>
  <div class="view-perfil-datos">
    <router-view></router-view>
  </div>
</template>

<script setup>
</script>

<style scoped>
</style>