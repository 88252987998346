<template>
  <div class="modal__bg" v-if="dialog">
    <div class="modal__container-md">
      <h3 class="tp-head-responsive">{{ $t('messagepay.txt_resultado_de_la_transaccion') }}</h3>
      <div class="body">
        <div class="content">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_medio_de_pago') }}</p>
          <span class="tp-body-responsive">{{form.brand}}</span>
        </div>
        <div class="content">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_numero_de_pedido') }}</p>
          <span class="tp-body-responsive" >{{form.card}}</span>
        </div>
        <div class="content">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_monto') }}</p>
          <span class="tp-body-responsive" >{{form.amount}}</span>
        </div>
        <div class="content">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_moneda') }}</p>
          <span class="tp-body-responsive" >{{form.moneda}}</span>
        </div>
        <div class="content">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_numero_de_orden') }}</p>
          <span class="tp-body-responsive" >{{form.nro_orden}}</span>
        </div>
        <div class="content">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_fecha_y_hora') }}</p>
          <span class="tp-body-responsive" >{{form.fecha}}</span>
        </div>
        <div class="content" v-if="success">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_productos') }}</p>
          <span class="tp-body-responsive" >{{form.type_order}}</span>
        </div>
        <div class="content">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_descripcion') }}</p>
          <span class="tp-body-responsive" >{{form.action}}</span>
        </div>
        <div class="content" v-if="success">
          <p class="tp-body-responsive">{{ $t('messagepay.txt_recomendacion') }}</p>
          <span class="tp-body-responsive" >{{ $t('messagepay.txt_por_favor_guarde_la_informacion_mostrada') }}</span>
        </div>

      </div>
      <ButtonPrimary @click="close">
        {{ $t('buttons.txt_aceptar') }}
      </ButtonPrimary>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
const order={
    names:null,
    brand:null,
    card:null,
    amount:null,
    moneda:"Soles",
    nro_orden:null,
    fecha:null,
    type_order:null,
    action:null    
}
export default {
        name:"message-confirmation",
  components: {ButtonPrimary},
  data(){
            return{
                dialog:false,
                loading:false,
                form:{...order},
                success:false
            }
        },
        methods:{
            open(item){
                console.log("mi order datos",item)
                this.form={...order}
                if(!item) return;
                this.dialog=true;
                this.success=item.success;
                this.form.brand=item.brand;
                this.form.card=item.card;
                this.form.amount=this.formatAmount(item.amount);
                this.form.moneda=item.moneda;
                this.form.nro_orden=item.nro_orden;
                this.form.fecha=this.formatDateLocal(item.fecha);
                this.form.type_order=this.formatTypeOrder(item.type_order);
                this.form.action=item.action;
            },
            close(){
                this.dialog=false;
                this.form={...order}
                if(this.success){
                    this.$router.push('/perfil/pedidos')
                }
                this.success=false;
            },
            formatAmount(amount){
                if(!amount) return '-';
                return `${Math.floor(amount/100)}.${(amount%100)}`

            },
            formatDateLocal(date){
                if(!date) return '-';
                let format = new Date(date);
                let zone=format.getTimezoneOffset()
                format.setHours(format.getHours()-(zone/60));
                let day='',hour='';
                hour=format.toISOString().substr(11,8)
                day=this.formatDay(format.toISOString().substr(0,10))
                return `${day} - ${hour}  `
            },   
            formatDay(date){
                if (!date) return null
                const [year, month, day ] = date.split("-")
                return `${day}/${month}/${year}`;
            },                     
            formatDate(fecha){
                let date=`${fecha.substr(4,2)}/${fecha.substr(2,2)}/${fecha.substr(0,2)}`;
                let hour=`${fecha.substr(6,2)}:${fecha.substr(8,2)}:${fecha.substr(10,2)}`;
                return `${date} ${hour}`
            },
            formatTypeOrder(type_order){
                if(!type_order) return;
                let order=''
                if(type_order==1){
                  order = this.$t('cobertura.txt_reserva');
                }
                if(type_order==2){
                  order = this.$t('messagepay.txt_recojo');
                }
                if(type_order==3){
                  order = this.$t('cobertura.txt_delivery');
                }
                return order;
            }
        },
}
</script>
<style scoped>
h3{
  text-align: center;
}

.body{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

span{
  text-align: right;
}
</style>