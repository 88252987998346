<template>
  <div class="modal__bg">
    <div class="modal_create_tarjeta">
      <div class="form-niubiz border">
        <div class="close_add_card" >
          <span class="close" @click="closeFormReload">x</span>
        </div>
        <div class="container">
          <div id="myPaymentForm">
            <div class="kr-embedded" kr-popin >

              <div class="kr-pan"></div>
              <div class="kr-expiry"></div>
              <div class="kr-security-code"></div>

              <button class="kr-payment-button"></button>

              <div class="kr-form-error"></div>
            </div>
          </div>
        </div>

        <div class="loading-tarjeta" v-show="loadingModal" id="cargando_izipay">
          <SpinnerComponent
              :type="'medium'"
              :spinnerColor="'var(--primary)'"
          >
          </SpinnerComponent>
          <p> {{ $t('paymentlzipay.txt_cargando')}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import KRGlue from "@lyracom/embedded-form-glue";
import KRGlue from '@lyracom/embedded-form-glue'
import { mapState } from "vuex";
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
export default {
  name:'modal.niubiz',
  props: {
    formToken: String,
    payload: Object
  },
  emits: ['successPay', 'closeIziForm', 'paymentAccepted'],
  components: {
    SpinnerComponent,
  },
  data () {
    return {
      loading: true,
      loadingModal: true,
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init(){
      try {
        console.log("iniciamos")
        this.loadingModal = true
        this.loading = true
        const { KR } = await KRGlue.loadLibrary("https://api.micuentaweb.pe", this.orderLocal.izipay_web_key)
        await KR.removeEventCallbacks()
        await KR.setFormConfig({       /* set the minimal configuration */
          formToken: this.formToken,
          'kr-language': 'es',                       /* to update initialization parameter */
        })
        const { result } = await KR.attachForm('#myPaymentForm') /* create a payment form */
        await KR.showForm(result.formId);
        await KR.openPopin()
        await KR.onSubmit((paymentresponse => {
          console.log("paymentresponse", paymentresponse)
          this.onPaid(paymentresponse)
        }))
        await KR.onPopinClosed(() => {
          this.$emit('closeIziForm')
        })
        this.loadingModal = false
        this.loading = false
        let boton_a_ocultar = this.getElementByXpath('//*[@id="myPaymentForm"]/div[2]/button')
        boton_a_ocultar.style.display = 'none'
      } catch (error) {
        console.warn(error)
        this.$toast.error(error)
        //this.loading=false;
      }
    },
    getElementByXpath(path) {
      return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    },
    async onPaid(event) {
      try {
        if (event.clientAnswer.orderStatus === "PAID") {
          let order={
            success:true,
            brand:event.clientAnswer.transactions[0].transactionDetails.cardDetails.effectiveBrand,
            card:event.clientAnswer.transactions[0].transactionDetails.cardDetails.pan,
            amount:event.clientAnswer.transactions[0].amount,
            moneda: this.$t('paymentlzipay.txt_soles'),
            type_order:this.payload.type_order,
            nro_orden:event.clientAnswer.transactions[0].shopId,
            fecha:event.clientAnswer.transactions[0].creationDate,
            action: this.$t('paymentlzipay.txt_aceptado'),
          }
          this.$emit("successPay",order);
          await this.sendOrderIzi();
        } else {
          let order={
            success:false,
            brand:event.clientAnswer.transactions[0].transactionDetails.cardDetails.effectiveBrand,
            card:event.clientAnswer.transactions[0].transactionDetails.cardDetails.pan,
            amount:event.clientAnswer.transactions[0].amount,
            moneda: this.$t('paymentlzipay.txt_soles'),
            type_order:this.payload.type_order,
            nro_orden:event.clientAnswer.transactions[0].shopId,
            fecha:event.clientAnswer.transactions[0].creationDate,
            action: this.$t('paymentlzipay.txt_denegado'),
          }
          this.$toast.error(order.action)
          this.$emit("successPay",order);
          this.$gtag.event(this.$t('paymentlzipay.txt_compra_fallida'), { metodo_pago: this.$t('paymentlzipay.txt_en_linea_izipay') })
          await this.close();
        }
      } catch (error) {
        console.log(error)
        this.$toast.error(this.$t('paymentlzipay.txt_ocurrio_un_problema'))
        await this.close();
      }
    },
    async sendOrderIzi () {
      try {
        this.loading = true
        this.$toast.success(this.$t('paymentlzipay.txt_venta_exitosa_te_notificaremos'))
        this.loading = false
        this.$emit('paymentAccepted')

      } catch (error) {
        this.loading = false;
        this.$toast.error('Error')
      }
    },
    async close(){
      try {
        this.loading=false;
        this.$emit('closeIziForm')
      } catch (error) {
        console.log(error)
      }
    },
    async closeFormReload() {
      await this.close()
    }

  },
  computed: {
    ...mapState([
      "orderLocal",
    ])
  }
}
</script>

<style scoped  lang="scss"  >

.container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.kr-popin-button{ display: none; }

.checkout__footer{
  width: 100%;
  z-index: 9;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.modal__bg {
  z-index: 999;
  font-size: 10px;
  font-family: "Primary-Tipography";
  flex-direction: column;
}

.modal_create_tarjeta {
  height: 80vh;
  border-radius: 5px;
  background: #ffffff;
  position: relative;
  width: 320px;
  .form-niubiz{
    padding: 10px;
    height: 100%;
    .close_add_card {
      text-align: end;
      font-size: 1.563rem;
      font-weight: bold;
      color: var(--primary);
      cursor: pointer;
    }
    .modal-head{
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo{
        display: block;
        height: 55px;
        width: 140px;
      }
      .title{
        text-align: center;
        padding: 0.5em 0;
        font-size: 2.1em;
      }
    }
    .modal_footer{
      width: 100%;
      text-align: end;
      display: none;
      .logo2{
        height: 70px;
        width: 90px;
      }
    }
  }
  .loading-tarjeta{
    font-size: 2em;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: #ffffffa6;
    gap: 10px
  }
}
@media only screen and (min-width: 920px) {
  .modal_create_tarjeta {
    width: 360px;
    .form-niubiz{
      width: 100%;
    }
  }
}
</style>
