<template>
  <article class="current-screen__terms">
    <h2 class="tp-head-responsive">Adquiere tu membresía {{ businessProgram.title }} y <br> ¡Maximiza tus beneficios! </h2>
    <div>
      <h3 class="tp-title-responsive">{{ $t('header.txt_beneficios') }}</h3>
      <ol type="1">
        <li v-for="(item, index) in businessProgram.benefits" :key="index">
          {{item}}
        </li>
        </ol>
    </div>
    <div>
      <h3 class="tp-title-responsive">{{ $t('footer.txt_terminos_y_condiciones') }}</h3>
      <ol type="1">
        <li v-for="(item, index) in businessProgram.terms_and_conditions" :key=index>
          {{item}}
        </li>
      </ol>
    </div>
    <CheckboxComponent
        :disabled="false"
        :required="true"
        :id="'checkbox_registro'"
        v-model="isAcceptedTerms"
    >
      {{ $t('clubBusiness.txt_tyc_club_business') }}
    </CheckboxComponent>
    <div class="list-inputs" v-if="false">
      <div class="inputs-item">
        <input
            type="checkbox"
            id="personal"
            value="0"
            :checked="cardDestination === 'personal'"
            @change="selectOption('personal')"
        >
        <label for="personal"
               class="chip"
               :class="{ 'chip-selected': cardDestination === 'personal' }"
        >
          Tarjeta personal
        </label>
      </div>
      <div class="inputs-item">
        <input
            type="checkbox"
            id="girf_card"
            value="1"
            :checked="cardDestination === 'gift_card'"
            @change="selectOption('gift_card')"
        >
        <label for="girf_card"
               class="chip"
               :class="{ 'chip-selected': cardDestination === 'gift_card' }"
        >
          Regalar una tarjeta
        </label>
      </div>
    </div>
    <ButtonPrimary
        type="button"
        :disabled="!isAcceptedTerms"
        :loading="validatingUser"
        @click="confirmTerms"
    >
      {{ $t('buttons.txt_empezar') }}
    </ButtonPrimary>
  </article>
</template>

<script setup>
import CheckboxComponent from "@/components/new/inputs/CheckboxComponent";
import {ref, defineEmits, onMounted, defineProps, computed, getCurrentInstance} from "vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import {useStore} from "vuex";
import CuponServices from "@/services/cupon-services";

const props = defineProps(['businessProgram', 'cardUsage']);
const emits = defineEmits(['updateScreen'])
const store = useStore()
const isAuthenticated = computed(() => store.state.isAuthenticated);
const {appContext} = getCurrentInstance();
const toast = appContext.config.globalProperties.$toast;

const isAcceptedTerms = ref(false);
const cardDestination = ref('personal');
const validatingUser = ref(false);

onMounted(() => {
  isAcceptedTerms.value = false;
  if (props.cardUsage !== null) {
    cardDestination.value = props.cardUsage;
  }
})

async function confirmTerms(){
  if (!cardDestination.value) {
    alert('Por favor selecciona una opción antes de continuar.');
    return;
  }

  if (!isAuthenticated.value) {
    store.commit('setShowModalLoginRegister', true)
    toast.info('Es necesario identificarse para continuar con su afiliación')
    return;
  }
  validatingUser.value = true
  let response = await CuponServices.verifyUserInOldMembershipDatabase()
  if (response.data.success) {
    emits('updateScreen', cardDestination.value, true);
  } else {
    emits('updateScreen', cardDestination.value, false);
  }

}

function selectOption (value) {
  cardDestination.value = cardDestination.value === value ? null : value;
}
</script>

<style scoped>
.current-screen__terms{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

h2{
  width: clamp(200px, 100%, 600px);
  margin-inline: auto;
  text-align: center;
}

h3{
  margin-bottom: 12px;
}

ol{
  padding-left: 20px;
}

li{
  list-style: auto;
}

.button{
  width: fit-content;
  margin-inline: auto;
}

.list-inputs{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.inputs-item{
  position: relative;
}

.inputs-item input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.inputs-item label{

}

.inputs-item input[type="checked"]:checked + label{
}
</style>