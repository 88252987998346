import axios from 'axios'
export default {
    createPayment(data={}){
        return  axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/izipay/token/shopping_cart/`,
            data 
            })
    },

    createMembershipPayment(data={}){
        return  axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/izipay/token/membership/`,
            data
        })
    }
}