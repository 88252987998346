<template>
  <article class="current-screen__progress">
    <ProgressIndicator
        v-if="!userHasPaid"
        :steps="steps"
        :currentStep="currentStep"
    />
    <!-- Entrega y fecha -->
    <ClubBusinessTypeDelivery
        v-if="currentStep === 1"
        @typeOrderChanged="handletypeOrder"
    />

    <!-- Información de entrega -->
    <ClubBusinessDeliveryInformation
        v-if="currentStep === 2"
        :typeOrder="typeOrder"
        @updateLocal="handleSelectedLocal"
    />

    <ClubBusinessDetailsPayment
        v-if="currentStep === 3"
        :localSelected="localSelected"
        :typeOrder="typeOrder"
        :businessProgram="businessProgram"
        :isOldUser="isOldUser"
        @editTypeOrder="currentStep = 1"
        @userGotFreeSuscription="userHasPaid = true"
    />

    <!-- Detalles y pago -->


    <div class="current-screen__buttons" v-if="!userHasPaid">
      <ButtonOutline type="button" @click="prevStep">Anterior</ButtonOutline>
      <ButtonPrimary type="button" @click="nextStep" :disabled="isButtonDisabled">Siguiente</ButtonPrimary>
    </div>
  </article>
</template>

<script setup>
import ProgressIndicator from "@/components/new/ProgressIndicator";
import {computed, defineEmits, defineProps, ref} from "vue";
import ButtonOutline from "@/components/new/buttons/ButtonOutline";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import ClubBusinessTypeDelivery from "@/views/ClubBusiness/ClubBusinessTypeDelivery";
import ClubBusinessDeliveryInformation from "@/views/ClubBusiness/ClubBusinessDeliveryInformation";
import ClubBusinessDetailsPayment from "@/views/ClubBusiness/ClubBusinessDetailsPayment";

const emits = defineEmits(['updateScreen'])
defineProps(['businessProgram', 'isOldUser'])

const steps = ["Modo de entrega", "Detalles y pago"];
const currentStep = ref(1);
const typeOrder = ref(null);
const localSelected = ref(null);
const userHasPaid = ref(false);

const prevStep = () => {
  if (currentStep.value === 1) {
    emits('updateScreen', 'terms', null);
    return
  }
  if (currentStep.value === 3  && typeOrder.value === '3') {
    currentStep.value = 1
    return;
  }
  currentStep.value--;
};

const nextStep = () => {
  if (typeOrder.value === '3' && currentStep.value === 1) {
    currentStep.value = 3
    return
  }
  currentStep.value += 1
};

function handletypeOrder(value){
  typeOrder.value = value
  console.log('Dirección', typeOrder.value)
}

function handleSelectedLocal(value){
  localSelected.value = value
}

const isButtonDisabled = computed(() => {
  if (currentStep.value === 3) {
    return true
  }
  if (currentStep.value === 1) {
    return typeOrder.value === null
  }
  if (typeOrder.value === '2') {
    return localSelected.value === null
  }
  return typeOrder.value === null
})

</script>

<style scoped>
.current-screen__progress{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.current-screen__buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
</style>