<template>
  <section class="steps-wrapper">
    <div
        class="step-item"
        v-for="(stepLabel, index) in steps"
        :key="index"
    >
      <span class="step-number"
            :class="{ 'step-number-active': index + 1 <= currentStep }"
      >
        {{ index + 1 }}
      </span>
      <span class="step-label">{{ stepLabel }}</span>
    </div>
    <div class="progres-bar">
      <span class="progress" :style="progressBarStyle "></span>
    </div>
  </section>
</template>

<script setup>
import {computed, defineProps} from "vue";
const props = defineProps(['steps', 'currentStep'])

const progressBarStyle = computed(() => {
  const totalSteps = props.steps.length;
  const progressPercentage = ((props.currentStep - 1) / (totalSteps - 1)) * 100;
  return {
    width: `${progressPercentage}%`,
    backgroundColor: "var(--primary)",
  };
})
</script>

<style scoped>
.steps-wrapper{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  height: 100px;
  width: 100%;
  max-width: 500px;
  margin-inline: auto;
}

.step-item{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.step-number{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: #E0E0E0;
  border-radius: 50%;
  color: var(--text-black);
  text-align: center;
}

.step-number-active{
  background-color: var(--primary);
  color: var(--text-white);
  transition: 400ms ease;
}

.step-label{
  top: 44px;
  font-size: 12px;
  position: absolute;
  text-align: center;
  width: 100px;
}

.progres-bar{
  position: absolute;
  top: 18px;
  width: 100%;
  height: 4px;
  background: rgb(222, 222, 222);
  z-index: -1;
  overflow: hidden;
}

.progress {
  position: absolute;
  height: 100%;
  background: var(--primary);
  transition: 300ms ease;
}
</style>