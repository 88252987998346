export default {
  "header": {
    "txt_inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "txt_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta"])},
    "txt_cobertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobertura"])},
    "txt_beneficios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficios"])},
    "txt_ingresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])},
    "txt_iniciar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesion"])},
    "txt_mi_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])}
  },
  "footer": {
    "txt_nuestra_cobertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra cobertura"])},
    "txt_trabaja_con_nosotros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabaja con nosotros"])},
    "txt_terminos_y_condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones"])},
    "txt_siguenos_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguenos en:"])},
    "txt_desarrollador_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollado por"])},
    "txt_club_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club Manu ¡Más beneficios!"])}
  },
  "clubBusiness": {
    "txt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adquiere tu membresía ", _interpolate(_named("businessname")), " y ¡Maximiza tus beneficios!"])},
    "txt_tyc_club_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto los términos y condiciones establecidas por el negocio."])}
  },
  "modal": {
    "txt_iniciar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "txt_registrarse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
    "txt_olvidaste_tu_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
    "txt_recuperala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupérala"])},
    "txt_o": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
    "txt_ingresar_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa con"])},
    "txt_he_leido_y_aceptop_los_terminos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y acepto los términos, condiciones, politícas y tratamiento de datos personales"])},
    "txt_he_leido_y_aceptop_los": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y acepto los"])},
    "txt_terminos_y_condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])},
    "txt_y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
    "txt_politicas_y_tratamiento_de_datos_personales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Políticas y Tratamiento de Datos Personales"])},
    "txt_si_tuvieras_inconvenientes_camino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tuvieras inconvenientes camino al restaurante, avísanos para retrasar tu orden"])},
    "txt_si_tuvieras_inconvenientes_camino_reprogramar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tuvieras inconvenientes camino al restaurante, avísanos para reprogramar tu reserva."])},
    "txt_deseas_confirmar_tu_compra": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Deseas confirmar tu compra para la dirección ", _interpolate(_named("direction")), "?"])},
    "txt_felicidades_estimado_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicidades estimado cliente! ¿Desea canjear sus puntos?"])},
    "txt_no_tienes_suficientes_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes suficientes puntos :("])},
    "txt_sigue_acumulando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Sigue acumulando!"])},
    "txt_quiero_recibir_notificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero recibir notificaciones del estado de mi pedido por Whatsapp."])}
  },
  "toast": {
    "txt_bienvenido_full_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenido ", _interpolate(_named("fullName"))])},
    "txt_bienvenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
    "txt_error_al_iniciar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al iniciar sesión"])},
    "txt_debe_ingresar_su_nombre_y_apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar su nombre y apellido"])},
    "txt_por_favor_ingresa_tu_numero_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa tu número celular"])},
    "txt_tu_celular_debe_tener_9_digitos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu celular debe tener 9 dígitos"])},
    "txt_numero_telefonico_debe_tener_9_digitos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número telefónico debe tener 9 dígitos"])},
    "txt_debe_ingresar_un_dni_o_ce_valido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar un DNI o CE válido"])},
    "txt_error_al_intentar_registrarse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al intentar registrarse"])},
    "txt_debe_aceptar_los_terminos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe aceptar los términos y condiciones para continuar."])},
    "txt_debe_ingresar_un_correo_valido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar un correo válido"])},
    "txt_hubo_un_error_con_el_servicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error con el servicio de Google Maps. Por favor, vuelve a intentarlo, o ubica la dirección en el mapa."])},
    "txt_ingrese_el_distrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el distrito"])},
    "txt_ingrese_su_direccion_recuerde_que": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su dirección. Recuerde que el motorizado irá al marcador en el mapa."])},
    "text_direccion_guardada_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección guardada con éxito."])},
    "txt_en_este_momento_no_hay_ningun_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este momento, no hay ningún local que brinde delivery."])},
    "txt_debe_ingresar_un_numero_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar un número celular válido"])},
    "txt_numero_celular_actualizado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número celular actualizado con éxito."])},
    "txt_presentacion_no_disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentación no disponible."])},
    "txt_el_producto_no_se_encuentra_disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El producto no se encuentra disponible"])},
    "txt_maximo_quantity_productos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Máximo ", _interpolate(_named("quantity")), " productos"])},
    "txt_hubo_un_error_al_cargar_la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al cargar la información del producto"])},
    "txt_el_producto_esta_agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El producto está agotado."])},
    "txt_debes_seleccionar_quantity_productos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Debes seleccionar ", _interpolate(_named("quantity")), " productos en la lista \"", _interpolate(_named("title")), "\""])},
    "txt_debes_seleccionar_entre_1_y_quantity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Debes seleccionar entre 1 y ", _interpolate(_named("quantity")), " productos en la lista \"", _interpolate(_named("title")), "\""])},
    "txt_agrego_al_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrego al carrito"])},
    "txt_item_actualizado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ítem actualizado con éxito."])},
    "txt_agregado_a_tu_carrito_de_compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Agregado a tu carrito de compras con éxito!"])},
    "txt_hubo_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo error"])},
    "txt_tiene_el_maximo_de_productos_escogidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiene el máximo de productos escogidos en la lista "])}
  },
  "placeholder": {
    "txt_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
    "txt_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "txt_nombre_y_apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y apellido"])},
    "txt_dni_o_ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNI o CE (para puntos)"])},
    "txt_nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "txt_apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "txt_numero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
    "txt_numero_de_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de celular"])},
    "txt_correo_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "txt_dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNI"])},
    "txt_fecha_de_nacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "txt_pronombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronombre"])},
    "txt_contrasena_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
    "txt_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "txt_confirmar_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nueva contraseña"])},
    "txt_buscar_por_plato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por plato"])},
    "txt_mostrar_todo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todo"])},
    "txt_ingresa_una_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa una dirección"])},
    "txt_ingresa_tu_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tu dirección"])},
    "txt_distrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distrito"])},
    "txt_con_que_nombre_guardara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Con qué nombre guardará esta dirección?"])},
    "txt_comentarios_adicionales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios adicionales"])},
    "txt_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "txt_horario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario"])},
    "txt_zona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona"])},
    "txt_ingrese_su_cupon_o_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su cupón o código de descuento"])},
    "txt_archivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
    "txt_ingresa_la_contrasena_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la contraseña actual"])},
    "txt_ingresa_la_nueva_contasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la nueva contraseña"])},
    "txt_vuelve_a_ingresar_la_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelve a ingresar la nueva contraseña"])},
    "txt_puestos_de_interes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puesto(s) de interés"])},
    "txt_elija_el_restaurante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija el restaurante"])},
    "txt_nombre_completo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])},
    "txt_adjunta_tu_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjunta tu CV"])},
    "txt_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
    "txt_domicilio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilio"])},
    "txt_tipo_de_documento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento"])},
    "txt_numero_de_documento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de documento"])},
    "txt_telefono_fijo_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono fijo/ celular"])},
    "txt_nombre_padre_madre_tutor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Padre/Madre/Tutor"])},
    "txt_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "txt_tipo_de_moneda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de moneda"])},
    "txt_monto_reclamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto reclamado"])},
    "txt_detalle_del_producto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle del producto o servicio adquirido"])},
    "txt_pedido_del_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido del cliente"])},
    "txt_ingrese_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese fecha"])},
    "txt_ingrese_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese ahora"])},
    "txt_razon_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón social"])},
    "txt_ingresar_su_nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar su nombre"])},
    "txt_ingresar_correo_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar correo eléctronico"])},
    "txt_ingrese_su_cupon_o_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su cupón o descuento"])},
    "txt_con_cuanto_vas_a_pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Con cuánto vas a pagar?"])},
    "txt_seleccione_una_opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una opción"])},
    "txt_nombre_y_apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y apellidos"])},
    "txt_canal_de_atencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de atención"])},
    "txt_numero_de_pedidos_opcional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pedido (opcional)"])},
    "txt_ingresar_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar código"])}
  },
  "buttons": {
    "txt_ordenar_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar ahora"])},
    "txt_empezar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar"])},
    "txt_comprar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "txt_guardar_cambios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
    "txt_guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "txt_confirmar_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])},
    "txt_recuperar_contrasena_por_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])},
    "txt_ordena_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordena ahora"])},
    "txt_ordena_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordena aquí"])},
    "txt_ver_mapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mapa"])},
    "txt_cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "txt_aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
    "txt_ingresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])},
    "txt_confirmar_la_ubicacion_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar la ubicación de entrega"])},
    "txt_anadir_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir dirección"])},
    "txt_seguir_comprando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir comprando"])},
    "txt_confirmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "txt_agregar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
    "txt_recibir_otro_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir otro código"])},
    "txt_comenzar_ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzar ahora"])},
    "txt_iniciar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar reserva"])},
    "txt_quiero_registrarme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero registrarme"])},
    "txt_aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "txt_borrar_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar cupón"])},
    "txt_continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "txt_modificar_o_cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar o cancelar"])},
    "txt_agregar_a_mi_calendario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a mi calendario"])},
    "txt_siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "txt_cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "txt_anterior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "txt_ver_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver cupones"])}
  },
  "home": {
    "txt_nuestra_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra carta"])},
    "txt_quieres_estar_al_tanto_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres estar al tanto de nuestras promociones y beneficios?"])},
    "txt_registrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Regístrate!"])},
    "txt_bienvenido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
    "txt_ya_se_encuentra_registrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya se encuentra registrado en nuestra web. ¡Gracias por su preferencia!"])},
    "txt_local_saturado_lo_sentimos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local saturado. Lo sentimos, no podemos atender más pedidos por ahora."])}
  },
  "carta": {
    "txt_explora_nuestra_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora nuestra carta"])}
  },
  "cobertura": {
    "txt_volver_al_inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al inicio"])},
    "txt_nuestra_cobertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra cobertura"])},
    "txt_ingresa_tu_dirección_para_encontrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tu dirección parar encontrar el local más cercano a ti."])},
    "txt_usar_ubicacion_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar ubicación actual"])},
    "txt_recojo_en_tienda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recojo en tienda"])},
    "txt_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "txt_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva"])},
    "txt_telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "txt_horarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horarios"])},
    "txt_ver_horarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver horarios"])},
    "txt_abierto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abierto"])},
    "txt_cerrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrado"])},
    "txt_ingresar_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar dirección"])}
  },
  "perfil": {
    "txt_volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "txt_mi_perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
    "txt_compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras"])},
    "txt_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos"])},
    "txt_adquirir_membresia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquirir membresía"])},
    "txt_maximiza_tus_beneficios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximiza tus beneficios"])},
    "txt_en_esta_seccion_encontaras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta sección encontrarás tus datos personales."])},
    "txt_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "txt_si_requieres_modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si requieres modificar tu contraseña, asegúrate de que sea segura."])},
    "txt_direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones"])},
    "txt_gestionar_tus_direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestiona tus direcciones de entrega para tus pedidos."])},
    "txt_anadir_una_direccion_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una dirección de entrega"])},
    "txt_mis_pedidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis pedidos"])},
    "txt_gestion_de_notificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "txt_pedidos_en_proceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos en proceso"])},
    "txt_beneficios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficios"])},
    "txt_conoce_tus_puntos_generador_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conoce tus puntos generados por tus compras y en qué canjearlos."])},
    "txt_puntos_actuales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos actuales"])},
    "txt_canjeados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canjeados"])},
    "txt_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupones"])},
    "txt_historial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial"])},
    "txt_eliminar_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar cuenta"])},
    "txt_cerrar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "txt_hola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hola"])},
    "txt_no_regresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, regresar"])},
    "txt_deseas_eliminar_la_direccion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Deseas eliminar la dirección ", _interpolate(_named("address")), "?"])},
    "txt_se_elimino_su_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se eliminó su cuenta con éxito."])},
    "txt_su_usuario_ya_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su usuario ya se encontraba eliminado. "])},
    "txt_ten_un_espacio_para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten un espacio para visualizar tus compras realizadas y calificar tus experiencias"])}
  },
  "perfilcambiarclave": {
    "txt_correo_para_cambio_de_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo para cambio de contraseña enviado con éxito."])},
    "txt_la_contrasena_debe_tener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe tener un mínimo de 6 caracteres"])},
    "txt_se_cambio_su_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se cambió su contraseña con éxito"])}
  },
  "cartarestaurante": {
    "txt_producto_agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto agotado"])}
  },
  "perfilmicuentalugares": {
    "txt_direccion_eliminada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección eliminada con éxito"])}
  },
  "perfilmicuentalugaresaddress": {
    "txt_direcciones_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones de entrega"])},
    "txt_nubo_un_error_con_el_servicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error con el servicio de Google Maps. Por favor, vuelve a intentarlo, o ubica la dirección en el mapa."])}
  },
  "perfilmicuentauser": {
    "txt_acumula_puntos_en_todos_los_canales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acumula puntos en todos los canales."])},
    "txt_adquiere_ofertas_exclusivas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquiere ofertas exclusivas por tu día."])},
    "txt_tu_nueva_contrasena_debe_tener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Tu nueva contraseña debe tener, por lo menos, 6 caracteres."])},
    "txt_se_actualizaron_sus_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se actualizaron sus datos correctamente."])},
    "txt_hubo_un_error_al_actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al actualizar sus datos."])},
    "txt_nueva_contrasena_debe_ser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nueva contraseña debe ser igual en ambos campos. Por favor verifique."])},
    "txt_ella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ella"])},
    "txt_el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Él"])}
  },
  "perfilmisbeneficiosbeneficios": {
    "txt_expira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expira:"])},
    "txt_sin_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin cupones:"])},
    "txt_estimado_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimado cliente"])},
    "txt_felicitaciones_desea_canjear_sus_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicitaciones! ¿desea canjear sus puntos?"])},
    "txt_si_canjear_mi_premio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, canjear mi premio"])},
    "txt_se_copio_el_codigo_del_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se copió el código del cupón correctamente."])},
    "txt_se_activo_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se activó cupón."])},
    "txt_ocurrio_un_error_al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un error al activar cupón"])}
  },
  "perfilmisbeneficioshistorial": {
    "txt_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupón"])},
    "txt_cupon_delivery_gratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupón Delivery Gratis"])},
    "txt_de_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de descuento"])},
    "txt_sin_historial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin Historial"])},
    "txt_monto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monto"])},
    "txt_porcentaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["porcentaje"])},
    "txt_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery"])},
    "txt_producto_gratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["producto gratis"])},
    "txt_descuento_fijo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descuento fijo sobre producto"])},
    "txt_descuento_porcentual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descuento porcentual sobre producto"])},
    "txt_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puntos"])}
  },
  "perfilmispedidositem": {
    "txt_pendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
    "txt_cocinando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocinando"])},
    "txt_listo_para_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo para recojo"])},
    "txt_anulado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulado"])},
    "txt_entregado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregado"])},
    "txt_mesa_reservada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesa reservada"])},
    "txt_en_camino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En camino"])}
  },
  "perfilmispedidostab1sp": {
    "txt_conoce_la_carta_personaliza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conoce la carta, personaliza tu pedido y compra ¡Todo en una sola web!"])}
  },
  "perfilmispedidostab2sp": {
    "txt_historial_vacio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial vacío"])}
  },
  "perfilmisreservas": {
    "txt_lleva_un_registro_de_todas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lleva un registro de todas tus reservas."])},
    "txt_reservas_programadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservas programadas"])}
  },
  "perfilmisreservastab": {
    "txt_dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
    "txt_mes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
    "txt_local_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local:"])},
    "txt_zona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona:"])},
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canitdad:"])},
    "txt_hora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora:"])},
    "txt_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
    "txt_no_hay_reservas_que_mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay reservas que mostrar"])},
    "txt_no_hay_historial_que_mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay historial que mostrar"])}
  },
  "componentlogin": {
    "txt_necesitamos_validar_tu_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitamos validar tu correo."])},
    "txt_por_tu_seguridad_te_hemos_enviado_un": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por tu seguridad te hemos enviado un correo para validar que eres tú. Por favor, revisa tu bandeja de entrada."])}
  },
  "createcard": {
    "txt_agregar_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar tarjeta"])},
    "txt_numero_de_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta"])},
    "txt_mes_de_expiracion_mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes de expiración (MM)"])},
    "txt_año_de_expiracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año de expiración (YYYY)"])},
    "txt_cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "txt_correo_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
    "txt_se_agrego_su_tarjeta_correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se agregó su tarjeta correctamente"])},
    "txt_espere_dat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere..."])},
    "txt_error_al_almacenar_datos_de_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al almacenar datos de tarjeta."])},
    "txt_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "txt_fecha_expiracion_mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha expiración (MM)"])},
    "txt_fecha_expiracion_yyyy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha expiración (YYYY)"])},
    "txt_error_al_almacenar_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al almacenar datos de su tarjeta."])}
  },
  "dropdownaddress": {
    "txt_direcciones_guardadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones guardadas"])},
    "txt_referencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia"])},
    "txt_lo_sentimos_te_encuentras_fuera_de_la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, te encuentras fuera de la zona de reparto de este local."])},
    "txt_local_saturado_lo_sentimos_no_podemos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local saturado. Lo sentimos, no podemos atender más pedidos por ahora."])},
    "txt_ver_carta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver carta"])},
    "txt_regresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar"])},
    "txt_local_cerrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local cerrado"])},
    "txt_si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
    "txt_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "txt_si_quiero_usarlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si, quiero usarlo"])}
  },
  "forgotpassword": {
    "txt_por_favor_ingresa_tu_correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingresa tu correo electrónico. Te enviaremos un link para crear tu nueva contraseña."])},
    "txt_cargando_dat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
    "txt_cambiar_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "txt_enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
  },
  "messagepay": {
    "txt_resultado_de_la_transaccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado de la transacción"])},
    "txt_medio_de_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio de pago:"])},
    "txt_numero_de_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pedido:"])},
    "txt_monto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto:"])},
    "txt_moneda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda:"])},
    "txt_numero_de_orden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de orden:"])},
    "txt_fecha_y_hora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha y  hora:"])},
    "txt_productos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos"])},
    "txt_descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción:"])},
    "txt_recomendacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendación:"])},
    "txt_por_favor_guarde_la_informacion_mostrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, guarde la Informacion mostrada para la verificación de su producto"])},
    "txt_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recojo"])}
  },
  "modalcancelbooking": {
    "txt_cancelacion_de_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación de reserva"])},
    "txt_se_anulara_su_reserva_y_se_le": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se anulará su reserva y se le generará un código de descuento por el valor de S/. XX.XX para que lo use en su próxima compra."])},
    "txt_cancelar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar reserva"])},
    "txt_resultado_de_la_transaccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado de la transacción"])}
  },
  "modalConfirmation": {
    "txt_queja_enviada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queja enviada"])},
    "txt_reclamo_enviado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamo enviado"])},
    "txt_tu_queja_ha_sido_enviada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu queja ha sido enviada con éxito, recibirás una copia con toda la información al correo ingresado."])},
    "txt_tu_reclamo_ha_sido_enviado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu reclamo ha sido enviado con éxito, recibirás una copia con toda la información al correo ingresado."])}
  },
  "modalValidateData": {
    "txt_completar_informacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar información"])},
    "txt_para_continuar_con_el_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar con el pago, completa los campos de"])}
  },
  "modaldelivery": {
    "txt_ubicacion_actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación actual"])},
    "txt_el_motorizado_ira_a_las_coordenadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El motorizado irá a las coordenadas en el mapa."])},
    "txt_direccion_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de entrega"])},
    "txt_la_direccion_es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección es:"])},
    "txt_casa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
    "txt_trabajo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajo"])},
    "txt_personalizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar"])},
    "txt_ingresar_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar dirección"])},
    "txt_no_encuentras_la_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No encuentras la dirección?"])},
    "txt_tu_direccion_es": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Tu dirección es ", _interpolate(_named("direction")), "?"])}
  },
  "modallistlocals": {
    "txt_local_de_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de recojo"])},
    "txt_seleccione_el_local_donde_ira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el local donde irá a recoger su pedido"])}
  },
  "modallocalschedules": {
    "txt_local_cerrado_nuestros_horarios_son": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local cerrado. Nuestros horarios son:"])}
  },
  "modalmicarrito": {
    "txt_mi_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi carrito"])},
    "txt_modalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalidad:"])},
    "txt_comer_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comer aquí"])},
    "txt_subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal:"])},
    "txt_extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras:"])},
    "txt_total_double_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
    "txt_no_hay_productos_en_su_canasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay productos en su canasta"])},
    "txt_explore_nuestra_tienda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore nuestra tienda"])},
    "txt_confirme_el_tipo_de_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme el tipo de pedido que desea realizar"])},
    "txt_para_llevar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para llevar"])},
    "txt_para_comer_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para comer aquí"])}
  },
  "modalpedirubicacion": {
    "txt_aceptas_que_google_acceda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Aceptas que Google acceda a tu ubicación?"])}
  },
  "modalphoneinput": {
    "txt_ultimo_paso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último paso"])},
    "txt_ingresa_tu_numero_de_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tu número de celular para completar tu registro"])}
  },
  "modalpredelivery": {
    "txt_necesitamos_tu_direccion_para_asignarte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Necesitamos tu dirección para asignarte el local con cobertura."])}
  },
  "modalproductcustomization": {
    "txt_opcional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
    "txt_deseas_agregar_algo_mas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas agregar algo más a tu pedido?"])},
    "txt_precio_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio base:"])}
  },
  "modalqualify": {
    "txt_salir_de_la_calificacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir de la calificación"])},
    "txt_encuesta_de_satisfaccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuesta de satisfacción Pedido"])},
    "txt_como_calificarias_tu_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo calificarías tu pedido en"])},
    "txt_muy_mala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy mala"])},
    "txt_excelente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente"])},
    "txt_agregar_comentario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar comentario"])},
    "txt_que_tanto_te_gustaron_estos_platos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué tanto te gustaron estos platos?"])},
    "txt_comentario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
    "txt_recomendarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Recomendarías"])},
    "txt_nada_probable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada probable"])},
    "txt_muy_probable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy probable"])},
    "txt_gracias_por_calificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por calificar!"])},
    "txt_tu_calificacion_nos_ayudara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu calificación nos ayudará a seguir mejorando."])},
    "txt_eficiencia_de_la_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiencia de la web / app"])},
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "txt_rapidez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapidez"])},
    "txt_calidad_de_la_comida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calidad de la comida"])},
    "txt_precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "txt_servicio_del_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio del delivery"])},
    "txt_buena_presentacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buena presentación"])},
    "txt_variedad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variedad"])},
    "txt_como_podemos_mejorar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos. ¿Cómo podemos mejorar?"])},
    "txt_aun_falta_una_estrella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún falta una estrella, ¿Qué nos faltó para ser excelentes?"])},
    "txt_muchas_gracias_que_fue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Muchas gracias! ¿Qué fue lo que más te gustó?"])},
    "txt_por_favor_debe_seleccionar_alguna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, debe seleccionar alguna estrella para avanzar."])},
    "txt_por_favor_seleccione_alguna_opcion_o_ingrese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione alguna opción o ingrese un comentario. ¡Gracias!"])},
    "txt_por_favor_califique_el_producto": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por favor, califique el producto ", _interpolate(_named("productName"))])},
    "txt_por_favor_seleccione_una_opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione una opción para conocer si nos recomendaría."])},
    "txt_gracias_por_tu_resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por tu reseña!"])},
    "txt_que_nos_falto_para_ser_excelentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué nos faltó para ser excelentes?"])}
  },
  "modalRecentCoupon": {
    "txt_deseas_usar_tu_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas usar tu cupón"])},
    "txt_en_este_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en este pedido?"])}
  },
  "modalAddCouponProduct": {
    "txt_deseas_usar_tu_cupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se agregará el producto "])},
    "txt_en_este_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la promoción para aplicar el descuento correspondiente."])}
  },
  "modalreschedule": {
    "txt_reprogramar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprogramar reserva"])},
    "txt_la_reprogramacion_sera_disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reprogramación será disponible solo por única vez."])},
    "txt_reprogramar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprogramar"])}
  },
  "modalselectcoupon": {
    "txt_podras_ingrsar_o_canjear_tus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canjea e ingresa tus cupones disponibles"])},
    "txt_canjea_tus_cupones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canjea tus cupones"])}
  },
  "modalsmsvalidation": {
    "txt_verificacion_de_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de cuenta"])},
    "txt_validar_por_whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar por WhatsApp"])},
    "txt_validar_por_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar por sms"])},
    "txt_por_favor_ingresa_tu_numero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingresa tu número celular para verificar tu cuenta"])},
    "txt_mensaje_reenviado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje reenviado con éxito. "])},
    "txt_ingresa_el_codigo_de_verificacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ingresa el código de verificación enviado al +51 ", _interpolate(_named("userPhone"))])}
  },
  "modalyapeplin": {
    "txt_paga_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga con"])},
    "txt_a_nombre_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nombre de:"])},
    "txt_opcion_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opción 1:"])},
    "txt_opcion_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opción 2:"])},
    "txt_con_el_numero_de_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con el número de celular."])},
    "txt_escanea_el_codigo_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanea el código QR y realiza el pago."])},
    "txt_al_numero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al número"])},
    "txt_luego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luego:"])},
    "txt_clic_aqui_para_subir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clic aquí para subir tu constancia"])},
    "txt_debe_seleccionar_una_imagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar una imagen con formato "])},
    "txt_se_copio_el_numero_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se copió el número celular correctamente."])},
    "txt_se_creo_su_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se creó su pedido correctamente"])},
    "txt_procesando_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesando pago"])}
  },
  "paymentlzipay": {
    "txt_cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
    "txt_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soles"])},
    "txt_aceptado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptado"])},
    "txt_denegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denegado"])},
    "txt_compra_fallida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra fallida"])},
    "txt_en_linea_izipay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en linea izipay"])},
    "txt_ocurrio_un_problema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un problema. Intente nuevamente."])},
    "txt_venta_exitosa_te_notificaremos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venta exitosa. Te notificaremos el estado de tu pedido."])}
  },
  "selectcard": {
    "txt_seleccionar_tarjeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tarjeta"])}
  },
  "barralateralizquierda": {
    "txt_deseas_cerrar_sesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas cerrar sesión?"])},
    "txt_si_salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, salir"])},
    "txt_no_seguir_navegando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, seguir navegando"])},
    "txt_eliminar_mi_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar mi cuenta"])},
    "txt_deseas_eliminar_tu_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas eliminar tu cuenta? Esta acción es irreversible."])},
    "txt_si_eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, eliminar"])},
    "txt_no_seguir_aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, seguir aquí"])},
    "txt_se_elimino_su_cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se eliminó su cuenta con éxito."])},
    "ext_su_usuario_ya_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su usuario ya se encontraba eliminado. "])}
  },
  "orderinfocomponet": {
    "txt_selecciona_tu_dispositivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona tu dispositivo"])},
    "txt_regresar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar"])},
    "txt_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido"])}
  },
  "itemproductocarrito": {
    "txt_ahorraste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorraste:"])}
  },
  "itemproductopagar": {
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad:"])}
  },
  "mobilefootercomponent": {
    "txt_ver_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver carrito:"])}
  },
  "ordeinfocomponent": {
    "txt_entrega_estiamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega estimada:"])},
    "txt_puntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puntos"])},
    "txt_repartidor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repartidor"])},
    "txt_ayudanos_a_mejorar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayúdanos a mejorar"])},
    "txt_calificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificar"])},
    "txt_datos_del_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del pedido"])},
    "txt_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha:"])},
    "txt_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery:"])},
    "txt_titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular:"])},
    "txt_celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celular:"])},
    "txt_comprobante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobante"])},
    "txt_descargar_comprobante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar comprobante"])},
    "txt_metodo_de_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])}
  },
  "ordenstatuscomponent": {
    "txt_cocinando_tu_orden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocinando tu orden"])},
    "txt_listo_para_recojo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo para recojo"])},
    "txt_en_camino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En camino"])},
    "txt_entregado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregado"])}
  },
  "orderdatailcomponent": {
    "txt_elegiste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegiste:"])},
    "txt_descuento_double_dat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento:"])},
    "txt_costo_de_envio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo de envío"])},
    "txt_bolsa_compostable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsa Compostable"])},
    "txt_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])},
    "txt_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "applyjob": {
    "txt_volver_al_inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al inicio"])},
    "txt_trabaja_con_nosotros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabaja con nosotros"])},
    "txt_unete_a_nuestra_familia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a nuestra familia"])},
    "txt_adjuntar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar"])},
    "txt_cambiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
    "txt_el_tamano_maximo_de_tu_archivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*El tamaño máximo de tu archivo debe ser 2 MB en formato pdf"])},
    "txt_he_leido_y_acepto_los": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y acepto los Términos y Condiciones y Politícas y Tratamiento de Datos personales"])},
    "txt_administrativo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrativo"])},
    "txt_azafatas_mozos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azafatas/ Mozos"])},
    "txt_cocina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocina"])},
    "txt_limpieza_y_mantenimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpieza y Mantenimiento"])},
    "txt_call_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call center"])},
    "txt_por_favor_debe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, debe adjuntar su CV"])},
    "txt_por_favor_espere_que_termine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espere que termine la carga de archivos"])},
    "txt_debe_seleccionar_al_menos_trabajo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar al menos 1 puesto de trabajo"])},
    "txt_debe_seleccionar_al_menos_gustaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar al menos 1 local en donde le gustaría trabajar"])},
    "txt_debe_ingresar_un_numero_de_dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar un número de DNI válido"])},
    "txt_debe_ingresar_un_numero_de_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar un número de celular válido"])},
    "txt_debe_ingresar_su_nombre_completo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar su nombre completo"])},
    "txt_debe_aceptar_los_terminos_y_condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe aceptar los términos y condiciones"])},
    "txt_recibimos_tus_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibimos tus datos con éxito. ¡Gracias por tu tiempo e interés!"])},
    "txt_hubo_un_error_por_favor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error. Por favor, escríbanos por WhatsApp"])}
  },
  "cambiarclave": {
    "txt_escribe_tu_nueva_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu nueva contraseña"])},
    "txt_tu_nueva_contrasena_debe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Tu nueva contraseña debe tener, por lo menos, 6 caracteres."])},
    "txt_la_contrasena_debe_ser_igual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe ser igual en ambos campos. Por favor verifique."])},
    "txt_cambiar_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])}
  },
  "librodereclamaciones": {
    "txt_libro_de_reclamaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro de Reclamaciones"])},
    "txt_conforme_a_lo_establecido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conforme a lo establecido en código de la Protección y Defensa del consumidor contamos con un Libro de Reclamaciones a tu disposición para que puedas registrar tu queja o reclamo acerca de algún pedido o transacción realizada."])},
    "txt_fecha_de_registro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de registro:"])},
    "txt_razon_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón Social:"])},
    "txt_ruc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUC:"])},
    "txt_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección:"])},
    "txt_identificacion_del_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación del local"])},
    "txt_identificacion_del_consumidor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación del consumidor reclamante"])},
    "txt_soy_menor_de_edad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy menor de edad"])},
    "txt_identificacion_del_bien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación del bien contratado"])},
    "txt_producto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
    "txt_servicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio"])},
    "txt_detalle_de_reclamacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle de Reclamación y Pedido del Consumidor"])},
    "txt_reclamo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamo"])},
    "txt_queja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queja"])},
    "txt_reclamo_disconformidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECLAMO: Disconformidad relacionada a los productos o servicios."])},
    "txt_queja_disconformidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUEJA: Disconformidad no relacionada a los productos o servicios, se realiza para manifestar el malestar o descontento del consumidor respecto a la atención al público."])},
    "txt_la_formulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI."])},
    "txt_el_proveedor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El proveedor deberá dar respuesta al reclamo en un plazo no mayor a treinta (30) días calendario, pudiendo ampliar el plazo hasta por treinta (30) días más, previa comunicación al consumidor."])},
    "txt_disconformidad_relacionada_a_los_productos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconformidad relacionada a los productos o servicios"])},
    "txt_disconformidad_no_relacionada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconformidad NO relacionada a los productos o servicios"])},
    "txt_reclamo_enviado_con_exito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamo enviado con éxito"])}
  },
  "paymentscreen": {
    "txt_prodeco_de_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de pago"])},
    "txt_carrito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrito"])},
    "txt_pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "txt_modalidad_de_entrega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalidad de entrega"])},
    "txt_cambiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
    "txt_deseas_programar_tu_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas programar tu pedido?"])},
    "txt_local_de_consumo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de consumo"])},
    "txt_comprobante_de_pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobante de pago"])},
    "txt_boleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleta"])},
    "txt_boleta_electronica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleta electrónica"])},
    "txt_factura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factura"])},
    "txt_ingresa_tus_datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tus datos"])},
    "txt_comentarios_opcional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios (opcional)"])},
    "txt_cupon_de_descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupón de descuento"])},
    "txt_resumen_del_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESUMEN DEL PEDIDO"])},
    "txt_producto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "txt_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "txt_costo_de_envio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo de envío:"])},
    "txt_delivery_gratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery gratis"])},
    "txt_pagar_en_linea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar en línea"])},
    "txt_pagar_en_efectivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar en efectivo"])},
    "txt_pagar_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar con"])},
    "txt_debe_ingresar_una_direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar una dirección de envío"])},
    "txt_el_producto_no_esta_disponible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El producto <strong>", _interpolate(_named("productName")), "</strong> no está disponible. Se eliminó de tu carrito."])},
    "txt_lo_sentimos_por_el_momento": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lo sentimos, por el momento no contamos con la opción <strong>", _interpolate(_named("modified")), "</strong> del producto <strong>", _interpolate(_named("productName")), "</strong>. Por favor, revísalo en tu carrito y actualiza tus preferencias."])},
    "txt_se_actualizo_el_precio": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se actualizó el <strong>precio</strong> de <strong>", _interpolate(_named("productName")), "</strong>"])},
    "txt_venta_exitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venta exitosa. ¡Te estaremos notificando el estado de tu pedido!"])},
    "txt_compra_exitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra exitosa"])},
    "txt_se_descontara_el_valor_de": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se descontará el valor de S/ ", _interpolate(_named("amount_discount"))])},
    "txt_se_descontara_un_porcentaje_de": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se descontará un ", _interpolate(_named("porcentaje")), "% del valor del carrito"])},
    "txt_se_descontara_el_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se descontará el costo de envío"])},
    "txt_cupon_valido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupón válido"])},
    "txt_debe_ingresar_un_codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar un código o cupón"])},
    "txt_el_local_no_ofrece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El local ", _interpolate(_named("localName")), " no ofrece ", _interpolate(_named("typeDeliveryName"))])},
    "txt_tipo_delivery_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type_delivery_name"))])},
    "txt_debe_ingresar_la_fecha_y_hora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ingresar la fecha y hora en la que desea tener su pedido programado."])},
    "txt_debe_seleccionar_el_tipo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar el tipo de comprobante de pago que desea."])},
    "txt_debe_seleccionar_un_metodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe seleccionar un método de pago"])},
    "txt_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "txt_en_linea_izipay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En línea izipay"])},
    "txt_comentario_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario:"])}
  },
  "reservas": {
    "txt_indique_el_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique el local:"])},
    "txt_indique_la_fecha_que_desee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique la fecha que desee reservar:"])},
    "txt_indique_la_cantidad_de_comensales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique la cantidad de comensales"])},
    "txt_local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
    "txt_fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "txt_cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "txt_escoja_el_horario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoja el horario de su preferencia:"])},
    "txt_dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
    "txt_mes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
    "txt_tarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarde"])},
    "txt_noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noche"])},
    "txt_a_las": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A las"])},
    "txt_hora_h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora h"])},
    "txt_para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
    "txt_personas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas"])},
    "txt_elije_una_zona_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elije una zona de preferencia:"])},
    "txt_solicitar_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar reserva"])},
    "txt_comensales_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comensales:"])},
    "txt_para_terminar_con_el_proceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para terminar con el proceso de reserva, pase a realizar el abono de"])},
    "txt_monto_que_sera_descontado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monto que será descontado a su consumo"])},
    "txt_titulo_reserva_programada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título: Reserva programada con éxito"])},
    "txt_nombre_del_cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del cliente"])},
    "txt_tu_reserva_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu reserva en Nom. de empresa ha sido programada con éxito."])},
    "txt_codigo_de_reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de reserva:"])},
    "txt_nota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota:"])},
    "txt_muestra_el_codigo_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestra el código QR para identificar tu reserva y canjear tu pago anticipado."])},
    "txt_le_recordamos_que_el_tiempo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le recordamos que el tiempo de toleracia es de máximo 20 minutos"])},
    "txt_politica_de_modificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de modificaciones y cancelaciones"])},
    "txt_desarrollador_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador por"])}
  },
  "unsubscribed": {
    "txt_se_anulo_la_suscripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se anuló la suscripción con éxito"])},
    "txt_dejaremos_de_enviarte_promociones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejaremos de enviarte promociones, ofertas, beneficios y comunicaciones afines."])}
  },
  "perfilmisgestionnotification": {
    "txt_gestiona_tus_notificaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestiona tus notificaciones de estado de pedidos y publicidad."])},
    "txt_notificaciones_de_publicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones de publicidad en la app"])},
    "txt_correos_con_promociones_exclusivas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correos con promociones exclusivas"])},
    "txt_whatsapp_de_estado_de_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp de estado de pedido"])},
    "txt_whatsapp_promocionales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp promocionales"])}
  },
  "whatsAppPaymentScreen": {
    "txt_pago_en_linea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAGO EN LÍNEA"])},
    "txt_resumen_del_pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen del pedido"])}
  },
  "modalOfflineUser": {
    "txt_opps_algo_salio_mal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Oops! algo salió mal"])},
    "txt_revisa_tu_conexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revisa tu conexión de internet y vuelve a intentarlo."])},
    "txt_volver_a_intentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a intentar"])}
  }
}