<template>
  <section class="club-business__wrapper">
    <div class="club-business__hero">
      <p class="back-icon tp-button-responsive" @click="$router.push('/')">
        <svg class="icon-close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.82505 13L12.725 17.9C12.925 18.1 13.0209 18.3334 13.0125 18.6C13.0042 18.8667 12.9 19.1 12.7 19.3C12.5 19.4834 12.2667 19.5792 12 19.5875C11.7334 19.5959 11.5 19.5 11.3 19.3L4.70005 12.7C4.60005 12.6 4.52922 12.4917 4.48755 12.375C4.44588 12.2584 4.42505 12.1334 4.42505 12C4.42505 11.8667 4.44588 11.7417 4.48755 11.625C4.52922 11.5084 4.60005 11.4 4.70005 11.3L11.3 4.70005C11.4834 4.51672 11.7125 4.42505 11.9875 4.42505C12.2625 4.42505 12.5 4.51672 12.7 4.70005C12.9 4.90005 13 5.13755 13 5.41255C13 5.68755 12.9 5.92505 12.7 6.12505L7.82505 11H19C19.2834 11 19.5209 11.0959 19.7125 11.2875C19.9042 11.4792 20 11.7167 20 12C20 12.2834 19.9042 12.5209 19.7125 12.7125C19.5209 12.9042 19.2834 13 19 13H7.82505Z" fill="currentColor"/>
        </svg>
        {{ $t('applyjob.txt_volver_al_inicio') }}
      </p>
      <img src="../../assets/images/rebrandable/aside-bg-carta-digital.webp" alt="" role="presentation">
    </div>
    <section class="section-main">
      <!-- Pantalla de términos y condiciones -->
      <ClubBusinessTermsConditions
          v-if="currentScreen === 'terms' && businessProgram != null"
          @updateScreen="showProgressScreen"
          :cardUsage="cardUsage"
          :businessProgram="businessProgram"
      />

      <!-- Pantalla de barra de progreso -->
      <ClubBusinessProgress
          v-if="currentScreen === 'progress'"
          :businessProgram="businessProgram"
          :isOldUser="isOldUser"
          @updateScreen="showProgressScreen"
      />
    </section>
    <LoadingBigComponent
      v-if="loadingInfo"/>
  </section>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue';
// import { useStore } from 'vuex';
import CuponServices from "@/services/cupon-services"
import ClubBusinessTermsConditions from "@/views/ClubBusiness/ClubBusinessTermsConditions";
import ClubBusinessProgress from "@/views/ClubBusiness/ClubBusinessProgress";
import LoadingBigComponent from "@/components/Recursos/LoadingBigComponent.vue";
// const store = useStore();
const {appContext} = getCurrentInstance();
const toast = appContext.config.globalProperties.$toast;

const currentScreen = ref('terms');
// const isAuthenticated = computed(() => store.state.isAuthenticated);
const businessProgram = ref(null);
const loadingInfo = ref(true);
const cardUsage = ref('personal');
const isOldUser = ref(false);

onMounted(async () => {
  await getMembershipInfo()
})

async function getMembershipInfo() {
  let response = await CuponServices.getMembershipProgram()
  if (response.data.success) {
    businessProgram.value = response.data.data
    console.log("info membresia", businessProgram.value)
  } else {
    toast.error(response.data.message)
  }
  loadingInfo.value = false
}

function showProgressScreen(letter, foundInOldDatabase){
  currentScreen.value = 'progress'
  if (letter != null) {
    cardUsage.value = letter
  }
  isOldUser.value = foundInOldDatabase
}
</script>

<style scoped>
.club-business__hero{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 250px;
  overflow: hidden;
  position: relative;
}

.back-icon{
  position: absolute;
  top: 20px;
  left: 16px;
  color: var(--text-white);
}

.back-icon:hover{
  color: var(--text-white);
}

.back-icon::after{
  width: 75%;
  background-color: var(--text-white);
}

.club-business__hero img{
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: auto;
}
@media screen and (min-width: 1240px){
  .back-icon{
    top: 40px;
    left: 40px;
  }
}
</style>